<template lang="pug">
    #Revistas
        div(v-for="ano, key, index in formatMagazines" v-if="index < displayedMagazines")
            h2(v-if="magazinesLength > 1") {{ key }}
            ul
                li(v-for="item, index in ano")       
                    .img
                        img(:src="item.image").image
                        .flip
                            p.edicao Edição {{ item.edition }}
                            p(v-if="item.mes && item.ano").data {{ item.mes }} {{ item.ano }}
                            a(:href="item.animatedVersion" target="_blank")
                                span(v-if="item.animatedVersion !== ''").animado Revista Animada
                            a(:href="item.defaultVersion" target="_blank")    
                                span(v-if="item.defaultVersion !== ''").estatico Revista Padrão
        button(@click="expandMagazines" v-if="magazinesLength > displayedMagazines").squareButton CARREGAR MAIS EDIÇÕES
</template>
 
<script>
import { props } from '@/mixins/component'
import list from './listRevistas'

export default {
    name: "revistas",
    props,

    data() {
        return {
            displayedMagazines: 1,
        }
    },
    methods: {
        expandMagazines() {
			this.displayedMagazines += 2
		},
    },
    created() {
        this.$store.dispatch('fetchMagazines')
    },
    computed: {
        magazines() {
            const rota = this.$route.path.split('/').slice(-1)
            return this.$store.getters.magazines(list[rota], process.env.VUE_APP_FILE_API_ROUTE)
        },
        magazinesLength() {
            return Object.keys(this.formatMagazines).length
        },
        formatMagazines() {
            this.magazines.sort((a,b) => {
                if ( b.ano > a.ano ) return 1
                if ( b.ano < a.ano ) return -1
                return 0
            })
            const revistas = this.magazines.reduce((prev, curr) => {
                if (prev[`Edições ${curr.ano}`]) {
                    prev[`Edições ${curr.ano}`].push(curr)
                }
                else {
                    prev[`Edições ${curr.ano}`] = [curr]
                }
                return prev
            }, {})
            Object.values(revistas).forEach(ano => {
                ano.sort((a, b) => {
                    if ( b.edition > a.edition) return 1
                    if ( b.edition < a.edition ) return -1
                    return 0
                })
            })
            return revistas;
        },
    },
}
</script>

<style lang="stylus" scoped src="./Revistas.styl"></style>